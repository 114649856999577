import { Link } from "react-router-dom";
import { cn } from "utils/helper";
import useAllProperties from "hooks/useAllProperties";
import LoadingSpinner from "components/LoadingSpinner";

export default function Home() {
  const { properties, loading, error } = useAllProperties();

  if (loading || properties.length === 0) {
    return (
      <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12">
        <LoadingSpinner />
      </div>
    );
  }

  if (error) {
    return (
      <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12">
        <p className="text-red-300 text-2xl">Error: {error}</p>
      </div>
    );
  }

  return (
    <div className="w-screen h-screen bg-blue-950 flex flex-col justify-center items-center gap-12 overflow-y-scroll">
      <div>
        <h1 className="text-5xl font-extrabold text-white mb-10">
          TrueVue Reality
        </h1>
        <h3 className="text-2xl text-slate-300 mb-10">
          Visit our property virtual experiences
        </h3>
      </div>

      <div className="flex flex-wrap justify-center gap-4">
        {properties?.map((property, index) => (
          <Link
            to={`/property-experience/${property.id}`}
            key={index}
            className={cn(
              "flex justify-center items-center px-4",
              "min-w-64 min-h-48 text-teal-200 font-bold text-2xl rounded-md",
              "border-2 border-teal-200",
              "hover:bg-teal-200 hover:text-blue-950"
            )}
          >
            <span>{property.title}</span>
          </Link>
        ))}
      </div>
    </div>
  );
}
