import React from 'react';
import { Html } from "@react-three/drei";
import useCommonMethods from "hooks/useCommonMethods";
import { DataPointType } from "utils/types";
import usePlayerWithDev from "hooks/usePlayerWithDev";
import { useFrame } from "@react-three/fiber";
import { ArrowHelper, Vector3, Quaternion } from "three";

type Props = {
  index: number;
  state: DataPointType;
  currentDatapointIndex: number;
  userGettingAwayRef: React.MutableRefObject<boolean>;
};

function DataPoint({ state, userGettingAwayRef }: Props) {
  const { arrayToVector3 } = useCommonMethods();
  const { player } = usePlayerWithDev();
  const cylinderRef = React.useRef<any>();
  const planeRef = React.useRef<any>();
  const yaxisValue = -3;

  useFrame(({ clock }) => {
    if (player) {
      const playerPosition = new Vector3(player.position.x, yaxisValue, player.position.z);
      const datapointPosition = arrayToVector3([state.position[0], yaxisValue, state.position[2]]);
      const direction = new Vector3().subVectors(datapointPosition, playerPosition).normalize();
      const distance = playerPosition.distanceTo(datapointPosition);

      // Control visibility and pulsating effect for the cylinder
      if (cylinderRef.current) {
        cylinderRef.current.visible = userGettingAwayRef.current;
        if (userGettingAwayRef.current) {
          const scale = 0.5 + 0.2 * Math.sin(clock.getElapsedTime() * 3); // Pulsate by changing scale over time
          cylinderRef.current.scale.set(scale, 1, scale);
        }
      }

      // // Update plane geometry
      // if (planeRef.current) {
      //   const midpoint = new Vector3()
      //     .addVectors(playerPosition, datapointPosition)
      //     .multiplyScalar(0.5); // Midpoint of the player and datapoint
      //   const rotation = new Quaternion().setFromUnitVectors(
      //     new Vector3(0, 1, 0), // Default plane normal
      //     direction.clone().normalize()
      //   );

      //   midpoint.y -= 5;

      //   planeRef.current.position.copy(midpoint);
      //   planeRef.current.quaternion.copy(rotation);
      //   planeRef.current.scale.set(0.5, distance, 1); // Stretch plane to match distance

      //   // Animate opacity
      //   const elapsed = clock.getElapsedTime();
      //   const opacity = 0.2 + 0.8 * Math.abs(Math.sin(elapsed * 0.75)); // Oscillates between 0.2 and 1
      //   planeRef.current.material.opacity = opacity;
      // }


      // Update plane geometry
      if (planeRef.current) {
        planeRef.current.visible = !userGettingAwayRef.current; // Hide the plane if userGettingAwayRef.current is true

        if (!userGettingAwayRef.current) {
          const midpoint = new Vector3()
            .addVectors(playerPosition, datapointPosition)
            .multiplyScalar(0.5); // Midpoint of the player and datapoint
          const rotation = new Quaternion().setFromUnitVectors(
            new Vector3(0, 1, 0), // Default plane normal
            direction.clone().normalize()
          );

          midpoint.y -= 5;

          planeRef.current.position.copy(midpoint);
          planeRef.current.quaternion.copy(rotation);
          planeRef.current.scale.set(0.5, distance, 1); // Stretch plane to match distance

          // Animate opacity
          const elapsed = clock.getElapsedTime();
          const opacity = 0.2 + 0.8 * Math.abs(Math.sin(elapsed * 0.75)); // Oscillates between 0.2 and 1
          planeRef.current.material.opacity = opacity;
        }
      }
    }
  });

  return (
    <group>
      {/* Sphere at the datapoint */}
      <mesh position={arrayToVector3(state.position)}>
        <sphereGeometry args={[1.25, 32, 32]} />
        <meshBasicMaterial color="blue" />
        <Html center style={{ color: '#78ff93', width: '120px' }}>
          <h1>{state.position.join(', ')}</h1>
        </Html>
      </mesh>

      {/* Arrow pointing from player to datapoint */}
      {/* <arrowHelper ref={arrowRef} args={[new Vector3(1, 0, 0), new Vector3(), 0, 'red']} /> */}

      {/* Vertical pulsating line from datapoint */}
      <mesh
        ref={cylinderRef}
        position={new Vector3(state.position[0], state.position[1] + 20, state.position[2])}
      >
        <cylinderGeometry args={[0.20, 0.20, 80, 8]} />
        <meshBasicMaterial color="orange" />
      </mesh>

      {/* Plane extending from player to datapoint */}
      <mesh ref={planeRef}>
        <planeGeometry args={[1, 1]} />
        <meshBasicMaterial color="yellow" side={2} transparent opacity={0.5} />
      </mesh>
    </group>
  );
}

export default DataPoint;
